export const pageModules = {
    // Sections
    DoubleCtaSection: () => import('../components/page-modules/modules/DoubleCtaSectionModule/DoubleCtaSectionModule'),
    MenuCarouselSection: () => import('../components/page-modules/modules/MenuCarouselSectionModule/MenuCarouselSectionModule'),
    GroceryCarouselSection: () => import('../components/page-modules/modules/GroceryCarouselSectionModule/GroceryCarouselSectionModule'),
    HorizontalTextImageSection: () => import('../components/page-modules/modules/HorizontalTextImageSectionModule/HorizontalTextImageSectionModule'),
    VerticalTextImageSection: () => import('../components/page-modules/modules/VerticalTextImageSectionModule/VerticalTextImageSectionModule'),
    ThreeColumnGridSection: () => import('../components/page-modules/modules/ThreeColumnGridSectionModule/ThreeColumnGridSectionModule'),
    FourColumnGridSection: () => import('../components/page-modules/modules/FourColumnGridSectionModule/FourColumnGridSectionModule'),
    HeroBannerSection: () => import('../components/page-modules/modules/HeroBannerSectionModule/HeroBannerSectionModule'),
    SecondaryNavSection: () => import('../components/page-modules/modules/SecondaryNavSectionModule/SecondaryNavSectionModule'),
    HorizontalBgTextImageSection: () => import('../components/page-modules/modules/HorizontalBackgroundTextImageSectionModule/HorizontalBackgroundTextImageSectionModule'),
    HorizontalImageCtaSection: () => import('../components/page-modules/modules/HorizontalImageCtaSectionModule/HorizontalImageCtaSectionModule'),
    ImageBannerSection: () => import('../components/page-modules/modules/ImageBannerSectionModule/ImageBannerSectionModule'),
    PatternedHeroSection: () => import('../components/page-modules/modules/PatternedHeroSectionModule/PatternedHeroSectionModule'),
    UserLocationSection: () => import('../components/page-modules/modules/UserLocationSectionModule/UserLocationSectionModule'),
    OurTeamSection: () => import ('../components/page-modules/modules/OurTeamSectionModule/OurTeamSectionModule'),
    HeadingImageSection: () => import('../components/page-modules/modules/HeadingImageSectionModule/HeadingImageSectionModule'),
    VideoHeroSection: () => import('../components/page-modules/modules/VideoHeroSectionModule/VideoHeroSectionModule'),
    ContentDividerSection: () => import('../components/page-modules/modules/ContentDividerSectionModule/ContentDividerSectionModule'),
    OurOpportunitiesSection: () => import('../components/page-modules/modules/OurOpportunitiesSectionModule/OurOpportunitiesSectionModule'),
    RestaurantsMapSection: () => import('../components/page-modules/modules/RestaurantsMapSectionModule/RestaurantsMapSectionModule'),
    BasicBackgroundHeroSection: () => import('../components/page-modules/modules/BasicBackgroundHeroSectionModule/BasicBackgroundHeroSectionModule'),
    RestaurantDetailsSection: () => import('../components/page-modules/modules/RestaurantDetailsSectionModule/RestaurantDetailsSectionModule'),
    TextCarouselSection: () => import('../components/page-modules/modules/TextCarouselSectionModule/TextCarouselSectionModule'),
    RetailerLogosGridSection: () => import('../components/page-modules/modules/RetailerLogosGridSectionModule/RetailerLogosGridSectionModule'),
    TextHeroSection: () => import('../components/page-modules/modules/TextHeroSectionModule/TextHeroSectionModule'),
    StoresMapSection: () => import('../components/page-modules/modules/StoresMapSectionModule/StoresMapSectionModule'),
    RecipeContentSection: () => import('../components/page-modules/modules/RecipeContentSectionModule/RecipeContentSectionModule'),
    BonusRecipeCarouselSection: () => import('../components/page-modules/modules/BonusRecipeCarouselSectionModule/BonusRecipeCarouselSectionModule'),
    FaqSection: () => import('../components/page-modules/modules/FaqsSectionModule/FaqsSectionModule'),
    SitemapSection: () => import('../components/page-modules/modules/SitemapSectionModule/SitemapSectionModule'),
    RestaurantsCarouselSection: () => import('../components/page-modules/modules/RestaurantsCarouselSectionModule/RestaurantsCarouselSectionModule'),
    ContactFormSection: () => import('../components/page-modules/modules/ContactFormSectionModule/ContactFormSectionModule'),
    BasicTextSection: () => import('../components/page-modules/modules/BasicTextSectionModule/BasicTextSectionModule'),
    CollapsibleContentSection: () => import('../components/page-modules/modules/CollapsibleContentSectionModule/CollapsibleContentSectionModule'),
    JobCategoriesSection: () => import('../components/page-modules/modules/JobCategoriesSectionModule/JobCategoriesSectionModule'),
    RecipeListingsSection: () => import('../components/page-modules/modules/RecipeListingsSectionModule/RecipeListingsSectionModule'),
    RegionChoiceSection: () => import('../components/page-modules/modules/RegionChoiceSectionModule/RegionChoiceSectionModule'),
    RegionFeaturedProductsSection: () => import('../components/page-modules/modules/RegionFeaturedProductsSectionModule/RegionFeaturedProductsSectionModule'),
    RegionRetailersSection: () => import('../components/page-modules/modules/RegionRetailersSectionModule/RegionRetailersSectionModule'),
    MenuListingsSection: () => import('../components/page-modules/modules/MenuListingsSectionModule/MenuListingsSectionModule'),
    GroceryListingsSection: () => import('../components/page-modules/modules/GroceryListingsSectionModule/GroceryListingsSectionModule'),
    BlogListingsSection: () => import('../components/page-modules/modules/BlogListingsSectionModule/BlogListingsSectionModule'),
    BlogPostSection: () => import('../components/page-modules/modules/BlogPostSectionModule/BlogPostSectionModule'),
    AllItsuLocationsSection: () => import('../components/page-modules/modules/AllItsuLocationsSectionModule/AllItsuLocationsSectionModule'),
    AreaRestaurantsListSection: () => import('../components/page-modules/modules/AreaRestaurantsListSectionModule/AreaRestaurantsListSectionModule'),
    RecentBlogPostsSection: () => import('../components/page-modules/modules/RecentBlogPostsSectionModule/RecentBlogPostsSectionModule'),
    NewsletterPreferencesSection: () => import('../components/page-modules/modules/NewsletterPreferencesSectionModule/NewsletterPreferencesSectionModule'),
    ProductRetailersMapSection: () => import('../components/page-modules/modules/ProductRetailersMapSectionModule/ProductRetailersMapSectionModule'),
    CardColumnsSection: () => import('../components/page-modules/modules/CardColumnsSectionModule/CardColumnsSectionModule'),
    LandingPageFormSection: () => import('../components/page-modules/modules/LandingPageFormSectionModule/LandingPageFormSectionModule'),
    MapSection: () => import('../components/page-modules/modules/MapSectionModule/MapSectionModule'),
    MultipleImageCtaSection: () => import('../components/page-modules/modules/MultipleImageCtaSectionModule/MultipleImageCtaSectionModule'),

    // Templates
    DynamicPageTemplate: () => import('../components/page-modules/DynamicPageTemplate/DynamicPageTemplate'),
    GroceryPageTemplate: () => import('../components/page-modules/templates/GroceryPageTemplateModule/GroceryPageTemplateModule'),
    ProductPageTemplate: () => import('../components/page-modules/templates/ProductPageTemplateModule/ProductPageTemplateModule'),
};

/**
 * Get the module name from the returned graphQL typename.
 *
 * @param {string} name
 *
 * @returns {string}
 */
export const getPageModuleName = (name) => {
    return name
        .replace('ComponentPageSections', '')
        .replace('ComponentPageTemplates', '');
};

/**
 * Check if the module is a page template.
 *
 * @param {string} name
 *
 * @returns {boolean}
 */
export const isPageTemplate = (name) => {
    return name.startsWith('ComponentPageTemplates');
};

/**
 * Check if the template is a dynamic page template.
 *
 * A dynamic page template is a page template that doesn't have it's own
 * registered template module, and instead falls back to
 * use the `DynamicPageTemplate`.
 *
 * @param {string} name
 *
 * @returns {boolean}
 */
export const isDynamicPageTemplate = (name) => {
    const moduleName = getPageModuleName(name);

    return isPageTemplate(name) && !pageModules[moduleName];
};

/**
 * Run all the `asyncData` hooks on the pages modules.
 *
 * @param {object} page
 * @param {object} context
 * @param {object} context.$strapi
 *
 * @returns {Promise<void>}
 */
export const runPageModulesAsyncData = async (page, context) => {
    for (const componentData of page.attributes.components) {
        const componentName = componentData.__typename;

        if (!componentName) {
            continue;
        }

        const moduleName = getPageModuleName(componentName);

        if (!pageModules[moduleName] && !isDynamicPageTemplate(componentName)) {
            continue;
        }

        if (pageModules[moduleName]) {
            const { default: resolvedComponent } = await pageModules[moduleName]();

            if (resolvedComponent.asyncData) {
                componentData.asyncData = await resolvedComponent.asyncData({
                    ...context,
                    attributes: componentData,
                });
            }
        }

        // If dynamic template we wants to run the `asyncData` hooks on each sub module
        if (isDynamicPageTemplate(componentName)) {
            const components = Object.entries(componentData)
                .filter(([, val]) => val && typeof val === 'object' && val.__typename)
                .reduce((components, [, val]) => {
                    val.__page = componentData?.__page;

                    return [
                        ...components,
                        val,
                    ];
                }, []);

            await runPageModulesAsyncData({
                attributes: {
                    components,
                },
            }, context);
        }
    }
};
